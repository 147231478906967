import React from 'react';
import './columns.css';
import iconInspection from '../../assets/qfc_icon_inspection.svg';
import iconOperation from '../../assets/qfc_icon_operation.svg';
import iconQuality from '../../assets/qfc_icon_quality.svg';
import iconTime from '../../assets/qfc_icon_time.svg';
// import iconVerified from '../../assets/verified.svg';

const LandingSection = () => {
  return (
    <div className="container">
		{/* <h2>Programmet som håller dig trygg</h2> */}
		<div className="columns">
			<div className="circle_column">
				<div className="circle">
					<img src={iconOperation} alt="Feature 1" className="feature-image" />
				</div>
				<div className="texts">
					<h4>Få överblick & kontroll</h4>
					<p>Få överblick av din verksamhets olika processer & flöden, och tillgång till kvalitetssäkrande funktioner som ger dig full kontroll.</p>
				</div>
			</div>
			<div className="circle_column">
				<div className="circle">
					<img src={iconTime} alt="Feature 1" className="feature-image" />
				</div>
				<div className="texts">
					<h4>Spara tid & pengar</h4>
					<p>Effektivisera arbetet med smidiga digitala funktioner och färdiga mallar - enkelt att använda, på alla plattformar.</p>
				</div>
			</div>
			<div className="circle_column">
				<div className="circle">
					<img src={iconQuality} alt="Feature 1" className="feature-image" />
				</div>
				<div className="texts">
					<h4>Håll dig trygg & säker</h4>
					<p>Vi säkerställer att din verksamhet blir kvalitetsäkrad på rätt sätt - få skriftliga rutiner, skräddarsydda kontroller, avvikelsehantering och mycket mer.</p>
				</div>
			</div>
			<div className="circle_column">
				<div className="circle">
					<img src={iconInspection} alt="Feature 1" className="feature-image" />
				</div>
				<div className="texts">
					<h4>Bli redo för inspektion</h4>
					<p>All nödvändig historik & dokumentation på plats inför revision eller annan myndighetskontroll.</p>
				</div>
			</div>
		</div>
		{/* <br/> */}
		{/* <p className="italic">En del säger revolution - vi säger; självklart!</p> */}
    </div>
  );
};

export default LandingSection;
