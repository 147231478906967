import React from 'react';
import './menu.css';
import logo from '../../assets/qfc_logo_main.svg';

function Header() {

  return (
    <div className="App-menu-wrapper">
      <div className="App-menu">
        <div className="app-menu-items">
          <a href="/"  tabindex="1">
            <img src={logo} className="app-logo" alt="logo" />
          </a>
          <a href='#about'tabindex="1">
            Vår lösning
          </a>
          <a href='#plans' tabindex="1">
            Priser
          </a>
          <a href='#contact' tabindex="1">
            Kontakt
          </a>
        </div>
        <div className="app-menu-buttons">
          <a href='https://app.qualityfoodcontrol.com/' className="button-secondary small" tabindex="1">Logga in</a>
          <a href='#plans' className="button-primary small" tabindex="1">Få tillgång</a>
        </div>
      </div>
    </div>
  );
}

export default Header;
