import React from 'react';
import facebook from '../../assets/social_facebook.svg';
import linkedin from '../../assets/social_linkedin.svg';
import footerIcon from '../../assets/qfc_symbol_dark.svg';
import './footer.css';
import FadeInSection from '../../animation/fadein_onscroll';


function Footer() {

return (
<FadeInSection>
    <footer className="footer row center main-padding">
        <div className="footer-items column" id="contact">
            <div className="column">
                <h2>Bli kvalitetssäkrad du med!</h2>
                <a tabindex="5" href="mailto:contact@qualityfoodcontrol.com?subject=Jag%20%C3%A4r%20intresserad%20av%20att%20veta%20mer%20om%20QFC&body=Hej!%0A%0AJag%20%C3%A4r%20intresserad%20av%20att%20veta%20mer%20om%20QFC%20(Quality%20Food%20Control)%2C%20v%C3%A4nligen%20kontakta%20mig%20f%C3%B6r%20mer%20information.%0A%0AMvh" className="button-primary large margin-right">Kontakta oss för mer info</a>
            </div>
            <br />
            <br />
            <div className="column">
            <div className="column">
                <a tabindex="5" className="arrow" href='/terms' aria-label="Läs våra användarvillkor">Användarvillkor</a>
                <a tabindex="5" className="arrow" href='/privacy' aria-label="Läs vår integritetspolicy om hur vi hanterar dina personuppgifter">Integritespolicy</a>
            </div>
            <div className="row">
                <a tabindex="5" className="social-icon" href='https://www.facebook.com/qualityfoodcontrol' target="blank" aria-label="Följ oss på Facebook">
                    <img src={facebook} className="" alt="följ oss på facebook" />
                    <span className="tooltip">Facebook</span>
                </a>
                <a tabindex="5" className="social-icon" href='https://www.linkedin.com/company/quality-food-control/' target="blank" aria-label="Följ oss på LinkedIn">
                    <img src={linkedin} className="" alt="följ oss på linkedin" />
                    <span className="tooltip">LinkedIn</span>
                </a>
            </div>
            <span>
                © 2024 TQM Sweden AB
            </span>
            </div>
        </div>
        <img src={footerIcon} className="symbol" alt="symbol" />
    </footer>
</FadeInSection>
);
}

export default Footer;