import React from 'react';
import './home.css';
import './terms.css';

import FadeInSection from '../animation/fadein_onscroll';

document.querySelectorAll('a[data-scroll]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

function Terms() {
  return (
    <div className="subpage-padding" id='start'>
      <FadeInSection>
        <h1>Användarvillkor</h1>
      </FadeInSection>
      <br />
      <div className="max-width">
        <FadeInSection>
            <h2>1. Introduktion</h2>
            <br />
            <h3>1.1 Översikt och Syfte:</h3>
            <p>TQM Sweden AB erbjuder tjänster som syftar till att hjälpa företag specialiserade på livsmedel att uppfylla livsmedelssäkerhetskrav och säkerställa att dokumenten associerade med livsmedelssäkerhet överensstämmer med lagar och bästa praxis. Detta avtal beskriver villkoren för användning av våra tjänster och plattformen “Quality Food Control” som administreras av “TQM Sweden AB” (hädanefter "TQM“).</p>
            <br />
            <h3>1.2 Acceptans:</h3>
            <p>Genom att acceptera villkoren och använda våra tjänster bekräftar användaren att de har läst villkoren noggrant. Din överenskommelse med oss, eventuella tjänsteorder och dessa villkor hänvisas gemensamt till som "Kontraktet".</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h2>2. Definitioner</h2>
            <h3>2.1 Definitioner:</h3>
            <p>Termen "Användare" eller "Du" refererar till den juridiska enheten som har ingått ett avtal med oss (inklusive personen som agerar för dess räkning) och har accepterat dessa användarvillkor ("Villkor").</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h2>3. Användningsvillkor</h2>
            <h3>3.1 Registrering:</h3>
            <p>Användaren måste genomgå en registreringsprocess för att få tillgång till och använda våra tjänster och plattformen Quality Food Control.</p>
            <h3>3.2 Åtkomst och Användning:</h3>
            <p>Villkor och begränsningar gäller för åtkomst och användning av tjänsten, och användaren måste följa dessa för att behålla åtkomst till tjänsten.</p>
            <h3>3.3 Användaruppförande:</h3>
            <p>Användaren måste uppföra sig på ett lagligt och etiskt sätt när de använder tjänsten och följa alla tillämpliga lagar och bestämmelser.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h2>4. Betalningsvillkor</h2>
            <h3>4.1 Priser:</h3>
            <h4>4.1.1. Individualiserad Prissättning</h4>
            <p>Alla priser för TQMs tjänster är individualiserade och anpassade efter varje kunds unika behov, förutsättningar och önskemål. Priset som kunden betalar kommer att avtalas mellan kunden och TQM baserat på en detaljerad diskussion och analys av kundens krav.</p>
            <h4>4.1.2. Offert och Avtal</h4>
            <p>Efter att kundens behov har identifierats och analyserats, kommer TQM att lämna en skriftlig offert till kunden, med detaljerade priser och villkor. Tjänsterna börjar endast efter att kunden har accepterat offerten och ett skriftligt avtal har undertecknats av båda parterna.</p>
            <h4>4.1.3. Prisändringar</h4>
            <p>Eventuella ändringar i prissättningen, vare sig det beror på ändrade kundbehov, marknadsförhållanden eller andra faktorer, kommer att kommuniceras tydligt och i förväg till kunden. Nya priser träder i kraft efter att ha avtalats skriftligt av båda parterna.</p>
            <h4>4.1.4. Betalningsvillkor</h4>
            <p>Betalning ska ske enligt de betalningsvillkor som anges i det skriftliga avtalet. Eventuella sena betalningar kan medföra ränta och andra avgifter i enlighet med gällande lag.</p>
            <h4>4.1.5. Övrigt</h4>
            <p>Alla priser är exklusive moms och eventuella andra skatter och avgifter, om inte annat anges.</p>
            <br />
            <h3>4.2 Återbetalningspolicy:</h3>
            <p>Vi erbjuder inga återbetalningar efter att köpet har genomförts och tjänsten har börjat användas.</p>
            <h3>4.3 Avbokning av tjänster:</h3>
            <p>Du kan när som helst avbryta ditt abonnemang, men eventuella betalningar för innevarande faktureringsperiod återbetalas inte.</p>
            <h3>4.4 Återbetalning vid tekniska problem (om tillämpligt):</h3>
            <p>Återbetalningar beviljas endast i undantagsfall där tjänsten har varit otillgänglig på grund av tekniska problem under en längre period enligt våra villkor.</p>
            <h3>4.5 Prisändringar:</h3>
            <p>Vi förbehåller oss rätten att justera priserna för våra tjänster. Eventuella prisändringar kommer att baseras på faktorer som inflation, indexhöjningar eller andra kostnadsökningar.</p>
            <p>Du kommer att meddelas om eventuella prisändringar i förväg. Vanligtvis sker detta minst 30 dagar innan prisändringen träder i kraft. Om du inte godkänner ändringen har du rätt att avsluta ditt abonnemang före det nya priset träder i kraft.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h2>5. Dataskydd och Sekretess</h2>
            <h3>5.1 Dataskydd:</h3>
            <p>Vi är engagerade i att skydda användardata och följer alla gällande dataskyddslagar och bestämmelser.</p>
            <h3>5.2 Integritetspolicy:</h3>
            <p>Vår integritetspolicy finns tillgänglig på vår webbplats och beskriver hur vi hanterar och skyddar användarnas personuppgifter.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h2>6. Immateriella Rättigheter</h2>
            <h3>6.1 Äganderätt:</h3>
            <p>Alla immateriella rättigheter, inklusive varumärken och upphovsrätt, ägs av TQM Sweden AB.</p>
            <h3>6.2 Licens:</h3>
            <p>Användare beviljas en licens för att använda våra tjänster enligt de villkor som anges i detta avtal.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h2>7. Ansvarsbegränsning och Garantier</h2>
            <h3>7.1 Ansvarsbegränsning:</h3>
            <p>Vårt ansvar är begränsat enligt vad som tillåts enligt tillämplig lag, och vi ger inga garantier utöver vad som uttryckligen anges i detta avtal.</p>
            <h3>7.2 Garantier och Åtaganden:</h3>
            <p>Vi erbjuder inga garantier eller åtaganden utöver vad som uttryckligen anges i detta avtal.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h2>8. Övrigt</h2>
            <h3>8.1 Ändringar av Avtalet:</h3>
            <p>Vi förbehåller oss rätten att ändra detta avtal när som helst, och det är användarens ansvar att regelbundet kontrollera avtalet för ändringar.</p>
            <h3>8.2 Lagval:</h3>
            <p>Detta avtal styrs av och tolkas i enlighet med svensk lag.</p>
            <h3>8.3 Tvistlösning:</h3>
            <p>Tvister som uppstår i samband med detta avtal ska lösas genom förhandlingar, och om en överenskommelse inte kan nås, ska tvisten lösas i en svensk domstol.</p>
            <h3>8.4 Kontaktinformation:</h3>
            <p>För frågor angående detta avtal, vänligen kontakta oss via <a class="link" href="mailto:contact@qualityfoodcontrol.com">contact@qualityfoodcontrol.com</a>.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h2>9. Godkännande</h2>
            <h3>9.1 Underskrift:</h3>
            <p>Genom att acceptera detta avtal godkänner användaren villkoren i avtalet.</p>
        </ FadeInSection>
        <br />
        <br />
      </div>
    </div>
  );
}

// ReactDOM.render(<App />, document.getElementById('root'));

export default Terms;
