import React, { useState } from 'react';
import checkmarkIcon from '../../assets/checkmark.svg';
import './plans.css';

const Plans = () => {
  const [isAnnual, setIsAnnual] = useState(true);

  const togglePlan = () => {
    setIsAnnual(!isAnnual);
  };

  return (
    <div className="comparison-table main-padding" id="plans" tabindex="4">
      <div className="comparison-title">
        <h2>Priser</h2>
        <p>Du får en allt-i-ett lösning för verksamhetsstyrning - med översikt av hela din verksamhet, HACCP plan, övervakning, egenkontroller, rapporter och mycket mer - helt utan bindningstid!</p>
      </div>
      
      <div className="toggle-container">
        <button className={`toggle-button ${isAnnual ? 'active' : ''}`} onClick={togglePlan} tabindex="4">
          Årsvis
          <div className={`discount ${!isAnnual ? '' : 'active'}`}>
            <span>Spara 10%</span>
          </div>
        </button>
        <button className={`toggle-button ${!isAnnual ? 'active' : ''}`} onClick={togglePlan} tabindex="4">
          Månadsvis
        </button>
      </div>

      <div className="plans">
        <div className="plan" tabindex="4">
          <div>
            <h3 className="highlighted">Premium</h3>
            <p className="sub-label plan-description">För små eller medelstora verksamheter med tillgång till alla funktioner</p>
            <div className="flex">
              {isAnnual ? (
                <>
                  <h4 className="inline-block line-through inactive">1105 kr</h4>
                  <h4 className="inline-block">995 kr</h4>
                  <p className="sub-label inline-block">/mån*</p>
                </>
              ) : (
                <>
                  <h4 className="inline-block">1105 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              )}
            </div>
            <div>
              {isAnnual ? (
                <>
                  <p className="sub-label explainer">*Vid årsvis betalning</p>
                </>
              ) : (
                <>
                </>
              )}
              
            </div>
            {/* <a href='/' className="button-primary">Kontakta oss</a> */}
            <div className="plan-section">
              <p className="sub-label tiny">Huvudfunktioner</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Översikt av din verksamhets processer & flöden</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Riskhantering & HACCP plan</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Övervakning med skräddarsydda kontroller</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Avvikelsehantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Rutin- & dokumentstyrning</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Leverantörs- & certifikatshantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Loggning & historik</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skapa rapporter - faroanalys, farostyrningsplan m.fl.</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Egenkontroll</li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Övrigt</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Obegränsat med användare</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />1 Enhet</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Filhantering med 1TB cloud-lagring</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Säkerhet & backup</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Använd på alla plattformar</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Alltid uppdaterat</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Bas support</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Ingen bindningstid</li>
              </ul>
            </div>
          </div>
          <br></br>
          <div className="plan-button">
            <a tabindex="4" href="mailto:contact@qualityfoodcontrol.com?subject=Jag%20%C3%A4r%20intresserad%20av%20QFC%20'Premium'&body=Hej!%0A%0AJag%20%C3%A4r%20intresserad%20av%20att%20f%C3%A5%20tillg%C3%A5ng%20till%20QFC%20'Premium'%2C%20v%C3%A4nligen%20kontakta%20mig%20f%C3%B6r%20mer%20information.%0A%0AMvh" className="button-primary">Kontakta oss</a>
          </div>
        </div>
        <div className="plan"  tabindex="4">
          <div>
            <h3>Enterprise</h3>
            <p className="sub-label plan-description">För större verksamheter med behov av skräddarsydda lösningar</p>
            <div>
              <h4 className="inline-block">Skräddarsytt pris</h4>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Huvudfunktioner</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Allt som ingår i 'Premium'</li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Övrigt</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skräddarsytt upplägg</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Exklusiv support</li>
              </ul>
            </div>
          </div>
          <div className="plan-button">
            <a  tabindex="4 " href="mailto:contact@qualityfoodcontrol.com?subject=Jag%20%C3%A4r%20intresserad%20av%20QFC%20'Enterprise'&body=Hej!%0A%0AJag%20%C3%A4r%20intresserad%20av%20att%20f%C3%A5%20tillg%C3%A5ng%20till%20QFC%20'Enterprise'%2C%20v%C3%A4nligen%20kontakta%20mig%20f%C3%B6r%20mer%20information.%0A%0AMvh" className="button-secondary">Kontakta oss</a>
          </div>
        </div>
        <div className="plan addons"  tabindex="4">
          <div>
            <h3 className="grey">Tillägg</h3>
            <div className="plan-section">
              <p className="sub-label tiny">Skriftliga rutiner</p>
              <ul className="addons-features">
                <li>KRAV<span className="price-small">• 99 kr/mån</span></li>
                <li>EKO<span className="price-small">• 99 kr/mån</span></li>
                <li>MSC / ASC<span className="price-small">• 99 kr/mån</span></li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Hantera anställda</p>
              <ul className="addons-features">
                <li>HR-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Arbetsmiljö-paket<span className="price-small">• 249 kr/mån</span></li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Väx med oss</p>
              <ul className="addons-features">
                <li>Fler enheter<span className="price-small">• 495 kr/mån per enhet</span></li>
                <li>Mer lagring<span className="price-small">• 199 kr/mån per 1TB</span></li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Bli certifierad</p>
              <ul className="addons-features">
                <li>BRC, FSSC (GFSI standard)<span className="price-small">*</span></li>
                <li>IP<span className="price-small">*</span></li>
                <li>Butiksstandard<span className="price-small">*</span></li>
                <li>Safe & legal<span className="price-small">*</span></li>
                <br></br>
                <p className="sub-label explainer">*Kontakta oss för offert</p>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Konsultation</p>
              <ul className="addons-features">
                <li>Få stöd av våra livsmedelsexperter med över 100 års kombinerad erfarenhet<span className="price-small">*</span></li>
                <br></br>
                <p className="sub-label explainer">*Kontakta oss för offert</p>
              </ul>
            </div>
          </div>

          <div className="plan-button">
              <a   tabindex="4" href="mailto:contact@qualityfoodcontrol.com?subject=Jag%20%C3%A4r%20intresserad%20av%20till%C3%A4ggstj%C3%A4nster%20i%20QFC&body=" className="button-secondary">Kontakta oss</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
