import React, { useEffect, useState } from "react";

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = React.useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    observer.observe(domRef.current);
    const domRefCurrent = domRef.current;

    return () => observer.unobserve(domRefCurrent);
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
      style={{ transitionDelay: `${props.delay}s` }}
    >
      {props.children}
    </div>
  );
}

export default FadeInSection;
