import React from 'react';
import './home.css';
import './terms.css';

import FadeInSection from '../animation/fadein_onscroll';

document.querySelectorAll('a[data-scroll]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

function Terms() {
  return (
    <div className="subpage-padding" id='start'>
      <FadeInSection>
        <h1>Integritetspolicy</h1>
        <br />
        <p>Giltig från: 2023-09-21</p>
      </FadeInSection>
      <br />
      <div className="max-width">
        <FadeInSection>
          <p>TQM Sweden AB, organisationsnummer 559280-3042, beläget på Parkvägen 2, 169 35 Stockholm, Sverige (hädanefter "TQM“) administrerar plattformen på https://www.qualityfoodcontrol.com, som skapar en digital arbetsyta för juridiska enheter specialiserade på livsmedel.</p>
          <p>TQM plattform och de tjänster den tillhandahåller är avsedda enbart för företagskunder, och därför kommer TQM endast att behandla personuppgifter av representanter som agerar på uppdrag av företagskunder i den mån det är nödvändigt för att tillhandahålla plattformstjänsten till företagskunder. Med det sagt, termen “Användare“ i TQMs sekretesspolicy ska referera till en representant som agerar på uppdrag av en företagskund.</p>
          <p>TQM garanterar att det följer kraven som uppstår från den allmänna dataskyddsförordningen 2016/679 vid behandling av data. Med denna sekretesspolicy, avser TQM att informera Användare om de behandlingsoperationer som deras data utsätts för.</p>
          <p>Vi har rätten att ändra denna sekretesspolicy ensidigt när som helst. I händelse av en sådan förändring, kommer vi att meddela Användarna.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h2>1. Insamlad data</h2>
          <br />
          <p>TQM ska behandla data om Användares som har frivilligt lämnats till TQM-plattformen. Användares förstår att inlämnandet av data till TQM är frivilligt, men de kommer inte att kunna använda TQM-plattformen och de tjänster den tillhandahåller om inte data lämnas in.</p>
          <p>TQM ska samla in och behandla följande data om Användares (hädanefter kollektivt benämnt som "Personuppgifter"):</p>
          <p>namn, telefonnummer och e-postadress;</p>
          <p>information om den juridiska enheten som representeras (företagsnamn, registerkod);</p>
          <p>Kreditkortsinformation för betalning av tjänsten;</p>
          <p>Annan data som Användaren väljer att dela med sig om sig själv på plattformen.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h2>2. Syfte & rättslig grund</h2>
          <br />
          <p>TQM ska behandla Personuppgifter enbart för de ändamål som fastställts av lagen och denna Integritetspolicy, inklusive följande ändamål: att skapa ett personligt konto för Användaren, identifiera och kontakta Användaren, tillhandahålla plattformstjänsten, tillhandahålla supporttjänster (inklusive användarstöd) och uppfylla förpliktelser som uppstår från lagen.</p>
          <p>TQM ska behandla Användares’ personuppgifter på grundval av följande:</p>
          <p>Det rättsliga avtalet mellan den juridiska enheten representerad av Användaren och TQM för att tillhandahålla plattformstjänsten till den, vilket också inkluderar att ge tillgång till plattformen;</p>
          <p>Samtycket som Användaren gett till TQM. På grundval av detta samtycke kommer TQM att behandla sådana data som inte är nödvändiga för att tillhandahålla plattformstjänsten i händelse av att Användaren ändå har valt att frivilligt lämna dem på plattformen (till exempel profilbilden);</p>
          <p>För att uppfylla relevanta lagliga skyldigheter.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h2>3. Dataöverföring & lagring</h2>
          <br />
          <p>TQM kan vidarebefordra Användares personuppgifter till tredje parter, till exempel revisorer, juridiska tjänsteleverantörer, eller andra personer som tillhandahåller tjänster till TQM (till exempel en molntjänstleverantör eller livsmedelsteknolog). TQM har gjort allt för att ovanstående tredje parter ska säkerställa konfidentialiteten och säkerheten för personuppgifterna.</p>
          <p>De tredje parter till vilka TQM vidarebefordrar Användares personuppgifter kan vara belägna utanför Europeiska ekonomiska samarbetsområdet där andra dataskyddsregler kan gälla och där Europeiska kommissionen inte har genomfört ett beslut om adekvat skydd. I dessa länder kan säkerheten för personuppgifter (inklusive skydd mot missbruk, obehörig åtkomst, offentliggörande, ändring eller förstörelse) inte garanteras på samma nivå som inom Europeiska unionen. Vid vidarebefordran av personuppgifterna utanför Europeiska ekonomiska samarbetsområdet garanterar TQM att lämpliga säkerhetsåtgärder vidtas. Om Användaren önskar få en kopia av sådana uppgifter, ber vi dig meddela TQM därefter.</p>
          <p>TQM har genomfört nödvändiga organisatoriska, fysiska och IT-säkerhetsåtgärder för att de uppgifter som publiceras på plattformen, inklusive personuppgifterna, ska skyddas från alla former av missbruk, obehörig åtkomst, offentliggörande, ändring eller förstörelse. Tredje parter har informerats om att de endast får använda Användares personuppgifter för det ändamål och i den omfattning som anges av TQM.</p>
          <p>TQM kommer att lagra personuppgifterna så länge som det föreskrivs av lagen men inte längre än vad TQM rimligen behöver för att uppfylla de syften för vilka uppgifterna samlades in eller behandlades; bland annat kan lagring av personuppgifterna tills eventuella krav från Användaren löper ut vara ett av dessa kriterier.</p>
          <p>TQM ska vidta rimliga åtgärder för att säkerställa att personuppgifterna är korrekta och pålitliga.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h2>4. Användarens rättigheter avseende datainsamling</h2>
          <br />
          <p>Användaren har följande rättigheter gällande datainsamling:</p>
          <p>att begära åtkomst till de personuppgifter om Användaren som samlats in av TQM och att begära en kopia av dessa personuppgifter;</p>
          <p>att begära rättelse, ändring eller radering av personuppgifterna om de är felaktiga eller inte behandlas i enlighet med gällande krav;</p>
          <p>i de fall som fastställts av lag, kräva att TQM begränsar insamlingen, behandlingen eller användningen av personuppgifterna om Användaren;</p>
          <p>i händelse av överträdelse av Användarens rättigheter, lämna in ett klagomål till Datainspektionen.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h2>5. Cookies</h2>
          <br />
          <p>På sin plattform använder TQM kakor (cookies) och andra spårningsverktyg för att förbättra användarupplevelsen på TQM-plattformen, vilket säkerställer hög kvalitet på den tjänst som tillhandahålls och användarvänlig navigering på plattformen.</p>
          <p>Kakor är i huvudsak textfiler som sparas på Användarens dator, smartphone eller andra enheter.</p>
          <p>De kakor som TQM använder på sin plattform kan delas in på följande sätt beroende på när de placeras:</p>
          <p>Tillfälliga eller sessionskakor går ut när Användaren lämnar plattformen eller stänger webbläsaren. TQM använder sessionskakor för att vissa funktioner på plattformen ska fungera (till exempel inloggning).</p>
          <p>Permanent kakor lagras permanent på Användarens enhet under den tid som anges i den permanenta kakan och aktiveras när Användaren besöker plattformen vars kaka har installerats. Syftet med permanenta kakor är att komma ihåg Användarens inställningar på TQM-plattformen. Till exempel använder TQM permanenta kakor för att komma ihåg språkinställningar eller spara information om företaget och dess verksamhetsort samt dess autentiseringskod.</p>
          <p>TQM använder också följande typ av kakor på sin plattform, som kan särskiljas efter syftet med deras placering:</p>
          <p>Nödvändiga kakor är viktiga för att navigera på webbsidan, använda dess funktioner och tillhandahålla de tjänster som Användare har valt. Webbplatsen kan inte tillhandahållas och begärda tjänster kan inte tillhandahållas till Användaren utan installation av sådana kakor;</p>
          <p>Preferenskakor gör det möjligt att komma ihåg de val Användaren har gjort (till exempel språkval) för att skapa en mer personlig och bekväm användarupplevelse.</p>
          <p>För att förhindra missbruk av plattformen kan TQM använda kakor som samlar in data om de enheter och program som används för plattformen.</p>
          <p>Användaren kan när som helst radera kakor eller blockera dem med hjälp av webbläsarinställningarna; dock kan vissa kakor vara nödvändiga för att tjänsten som plattformen tillhandahåller ska fungera. I det avseendet förstår Användaren att vissa funktioner på plattformen kanske inte fungerar korrekt om kakor raderas eller blockeras.</p>
          <p>Följande typer av kakor används på webbplatsen:</p>
          <p>Strikt nödvändiga/essentiella kakor. Dessa kakor är nödvändiga för att du ska kunna röra dig runt på webbplatsen och använda dess funktioner, som att komma åt säkra områden på webbplatsen. Utan dessa kakor kan inte de tjänster du har begärt tillhandahållas. Dessa kakor samlar inte in information som identifierar en besökare.</p>
          <p>Prestandakakor. Dessa kakor samlar in information om hur besökare använder en webbplats, till exempel vilka sidor besökarna oftast går till och om de får felmeddelanden från webbsidor. Dessa kakor samlar inte in information som identifierar en besökare. All information som dessa kakor samlar in är aggregerad och därför anonym. Den används endast för att förbättra hur en webbplats fungerar.</p>
          <p>Funktionalitetskakor. Dessa kakor låter webbplatsen komma ihåg val du gör (som ditt användarnamn, språk eller den region du befinner dig i) och ger förbättrade, mer personliga funktioner. De kan också användas för att tillhandahålla tjänster du har begärt, som att titta på en video eller kommentera en blogg. Informationen som dessa kakor samlar in kan vara anonymiserad, och de kan inte spåra din surfaktivitet på andra webbplatser.</p>
          <p>Beteendestyrd reklam och meddelandekakor. Dessa kakor används för att leverera annonser och meddelanden som är mer relevanta för dig och dina intressen. De används också för att begränsa antalet gånger du ser en annons eller meddelande samt hjälpa till att mäta effektiviteten i reklam- och meddelandekampanjer. De kommer ihåg att du har besökt en webbplats och denna information delas med andra organisationer, som annonsörer eller meddelandetjänstleverantörer. Ganska ofta kommer målinriktade eller reklamkakor att vara länkade till webbplatsfunktionalitet som tillhandahålls av den andra organisationen.</p>
          <p>Ytterligare information om användningen av kakor samt instruktioner för att inaktivera kakor finns tillgängliga på webbsidan.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h2>6. Kontaktinformation</h2>
          <br />
          <p>Om Användaren önskar utöva någon rättighet fastställd i avsnitt 4 av Integritetspolicyn eller få ytterligare information om behandlingen av dess rättigheter, vänligen kontakta TQM via e-postadress: <a class="link" href="mailto:contact@qualityfoodcontrol.com">contact@qualityfoodcontrol.com</a>.</p>
        </FadeInSection>
      </div>
    </div>
  );
}

// ReactDOM.render(<App />, document.getElementById('root'));

export default Terms;
